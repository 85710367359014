import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	ModalHeader,
	Modal,
	ModalBody,
	Row,
	Form,
	Label,
	FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAsyncDebounce } from "react-table";
import { hasGroup } from "../../api/session_helper";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import Select from "react-select";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import PickingsTable from "./PickingsTable";
import Pagination from "../../Components/Commons/Pagination";
import ConfirmModal from "../../Components/Commons/ConfirmModal";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPickingsQuery,
	useGetPickingStatusesTypesQuery,
	useCreatePickingMutation,
} from '../../api/api'

const Pickings = ({ t, i18n }) => {

	let navigate = useNavigate();

	const location = useLocation();

	let kind;
	if(location.pathname == '/inbound-pickings'){
		kind = 0;
	} else if (location.pathname == '/outbound-pickings'){
		kind = 1;
		if(hasGroup(['ReverseLogisticsOperator'])){
			navigate('..');
		}
	} else if (location.pathname == '/return-pickings'){
		kind = 2;
		if(hasGroup(['WarehouseOperator'])){
			navigate('..');
		}
	}

	//const [modal, setModal] = useState(false);
	const [createPickingInModal, setCreatePickingInModal] = useState(false);
	const [createdPicking, setCreatedPicking] = useState(null);
	const [pickingInCreatedModal, setPickingInCreatedModal] = useState(false);
	const [pickingsStatuses, setPickingsStatuses] = useState(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);
	//const [billingStartDate, setBillingStartDate] = useState(null);
	//const [billingEndDate, setBillingEndDate] = useState(null);
	const [campaign, setCampaign] = useState(null);
	const [driverSearch, setDriverSearch] = useState(null);

	const { 
		data: pickingsPages,
		error,
		isFetching: pickingsLoading,
		isSuccess: pickingsSuccess,
	} = useGetPickingsQuery(
		{ 
			page: currentPage,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
			campaign: campaign,
			driver: driverSearch,
			statuses: pickingsStatuses,
			kind: kind
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if(pickingsPages){
			if(!hasGroup(['Manager', 'OperationalManager', 'RouteManager'])){
				setPickingsStatuses([1]);
			}
		}
	}, [pickingsPages]);

	const [createPicking, { 
		error: pickingCreationErrors, 
		isLoading: pickingCreationLoading,
		isSuccess: pickingCreationSuccess,
		data: picking
	}] = useCreatePickingMutation();

	const { 
		data: pickingStatuses,
		error: pickingStatusesErrors,
		isFetching: isPickingStatusesLoading,
		isSuccess: isPickingStatusesSuccess
	} = useGetPickingStatusesTypesQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: false
		}
	);

	const handlePickingState = (selection) => {
		if(hasGroup(['Manager', 'OperationalManager', 'RouteManager'])){
			setCurrentPage(1);
			let pickingsStatuses = selection.map((status, key) => {
				return status.value;
			});
			setPickingsStatuses(pickingsStatuses.length > 0 ? pickingsStatuses : null);
		}
	};

	useEffect(() => {
		if(pickingCreationSuccess){
			setCreatePickingInModal(false);
			setCreatedPicking(picking);
			setPickingInCreatedModal(true);
		}
		if(pickingCreationErrors){
			setCreatePickingInModal(false);
			let errorString;
				
			if(pickingCreationErrors.data instanceof Array){
				errorString = error.data.join(', ');
			} else if(pickingCreationErrors.error){
				errorString = pickingCreationErrors.error;
			} else {
				errorString = "Unknown error"
			}
			setErrorTitle("Error when trying to import");
			setErrorDescription(errorString);
			setErrorModal(true);
		}
	}, [pickingCreationSuccess, pickingCreationErrors]);

	const handleCreateInputPicking = () => {
		const newInputPicking = {
			kind: 0,
		};
		createPicking(newInputPicking);
	};

	const handleNavigateToPicking = () => {
		let data = createdPicking;
		navigate(`../pickings/${data.id}`);
	};

	const onChange = useAsyncDebounce((value) => {
		setDriverSearch(value);
	}, 200);

	document.title = "Sistema de pedidos PROGISTICA";

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title={t("Pickings List")} pageTitle={t("Pickings")} />
				<ConfirmModal
					show={createPickingInModal}
					onConfirmClick={() => handleCreateInputPicking()}
					onCloseClick={() => setCreatePickingInModal(false)}
					title={t("Create new input picking")}
					question={t("Do you want to create an Inbound Picking linked to you?")}
					yesQuestion={t("Yes, create it!")}
				/>
				<ConfirmModal
						show={pickingInCreatedModal}
						onConfirmClick={handleNavigateToPicking}
						onCloseClick={() => setPickingInCreatedModal(false)}
						title={t("Input picking created")}
						question={t("You will be redirected to the picking detail")}
						yesQuestion={t("Ok")}
					/>
				<Row>
					<Col lg={12}>
						<Card id="pickingsList">
							<CardHeader className="border-0">
								<Row className="align-items-center gy-3">
									<div className="col-sm">
										<h5 className="card-title mb-0">{t("Pickings")}</h5>
									</div>
									{(hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'WarehouseOperator']) && (location.pathname == '/inbound-pickings')) && (
										<div className="col-sm-auto">
											<button
												type="button"
												className="btn btn-primary w-100"
												id="create-btn"
												onClick={() => { setCreatePickingInModal(true); }}>
												<i className="ri-add-line align-bottom me-1"></i> {t("Create Input Picking")}
											</button>
										</div>
									)}
								</Row>
							</CardHeader>
							<CardBody className="pt-0">
								<div>
									<Row className="mb-3">
										<CardBody className="border border-dashed border-end-0 border-start-0">
											<form>
												<Row>
													<React.Fragment>

														{pickingStatuses && pickingStatuses.statuses && hasGroup(['Manager', 'OperationalManager', 'RouteManager']) && (
															<Col sm={6} className="col-xxl-3">
																<Select
																	isMulti
																	placeholder={t("Picking State")}
																	classNamePrefix="form-select"
																	isLoading={isPickingStatusesLoading}
																	isClearable={true}
																	name="types"
																	onChange={handlePickingState}
																	options={pickingStatuses.statuses.map((eventType, key) => {
																		return {
																			...eventType,
																			value: eventType.value,
																			label: `${eventType.value} - ${eventType.display}`,
																		};
																	})}
																/>
															</Col>
														)}

														<Col sm={6} className="col-xxl-3">
															<Flatpickr
																className="form-control"
																id="datepicker-created-at"
																placeholder={t("Created at")}
																options={{
																	altInput: true,
																	altFormat: "F j, Y",
																	mode: "range",
																	dateFormat: "DD-MM-YYYY",
																}}
																onChange={(date) => {
																	setCurrentPage(1);
																	if(date[0]!== undefined){
																		const date1 = moment(date[0]).format("DD-MM-YYYY");
																		setCreationStartDate(date1);
																	} else {
																		setCreationStartDate(null);
																	}
																	if(date[1]!== undefined){
																		const date1 = moment(date[1]).format("DD-MM-YYYY");
																		setCreationEndDate(date1);
																	} else {
																		setCreationEndDate(null);
																	}
																}}
															/>
														</Col>
														
														{/*<Col sm={6} className="col-xxl-3">
															<div>
																<Flatpickr
																	className="form-control"
																	id="datepicker-billing-date"
																	placeholder={t("Billing Day")}
																	options={{
																		altInput: true,
																		altFormat: "F j, Y",
																		mode: "range",
																		dateFormat: "DD-MM-YYYY",
																	}}
																	onChange={(date) => {
																		setCurrentPage(1);
																		if(date[0]!== undefined){
																			const date1 = moment(date[0]).format("DD-MM-YYYY");
																			setBillingStartDate(date1);
																		} else {
																			setBillingStartDate(null);
																		}
																		if(date[1]!== undefined){
																			const date1 = moment(date[1]).format("DD-MM-YYYY");
																			setBillingEndDate(date1);
																		} else {
																			setBillingEndDate(null);
																		}
																	}}
																/>
															</div>
														</Col>*/}

														<Col sm={6} className="col-xxl-3">
															<Input
																name="campaignId"
																id="id-field"
																className="form-control"
																placeholder={t("Campaign Id")}
																type="text"
																validate={{
																	required: { value: true },
																}}
																onChange={(e) => {
																	setCurrentPage(1);
																	setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
																}}
															/>
														</Col>

														<Col sm={6} className="col-xxl-3">
															<Input
																name="orderId"
																id="id-field"
																className="form-control"
																placeholder={t("Driver")}
																type="text"
																onChange={(e) => {
																	setCurrentPage(1);
																	onChange(e.target.value);
																}}
															/>
														</Col>
											
													</React.Fragment>
												</Row>
											</form>
										</CardBody>
									</Row>
									<PickingsTable
										data={pickingsPages != null ? pickingsPages.results : []}
										isLoading={pickingsLoading}
										isSuccess={pickingsSuccess}
										error={error}
									/>
								</div>
								{pickingsPages && !isEmpty(pickingsPages.results) && (
									<Pagination
										totalRows={pickingsPages.count}
										pageChangeHandler={setCurrentPage}
										rowsPerPage={25}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				
			</Container>
		</div>
	);
};

export default withTranslation()(Pickings);

