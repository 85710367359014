import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { withTranslation } from 'react-i18next';
//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Commons/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Cookies from 'js-cookie';

// actions
import { resetLoginFlag } from "../../slices/thunks";

import { hasGroup } from "../../api/session_helper";

import { 
	useAuthMutation, 
	useGetUserProfileQuery,
	useDriverSearchMutation
} from '../../api/api'

//import images

const Login = ({ t, i18n }) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [userLogin, setUserLogin] = useState([]);
	const [passwordShow, setPasswordShow] = useState(false);
	const [skipProfile, setSkipProfile] = useState(true);

	const [auth, 
	{ error: errorMsg, 
		isLoading: loading,
		isSuccess: isAuthSuccess,
		data: user
	}] = useAuthMutation();

	const { 
		data: profile,
		error: profileError,
		isFetching: profileLoading,
		isSuccess: profileSuccess
	} = useGetUserProfileQuery(
		{
			refetchOnMountOrArgChange: true,
			skip: skipProfile,
		}
	);

	useEffect(() => {
		if (isAuthSuccess) {
			Cookies.set('authUser', JSON.stringify(user), /*{ expires: new Date(new Date().getTime() + 60 *1000) }*/);
			debugger;

			setSkipProfile(false);
		}
	}, [isAuthSuccess]);

	useEffect(() => {
		if (profileSuccess) {			
			Cookies.set('first_name', profile.first_name);
			Cookies.set('last_name', profile.last_name);
			Cookies.set('email', profile.email);
			Cookies.set('groups', JSON.stringify(profile.groups));
			if(!hasGroup(['CompanyAdministrator'])){
				navigate('/order-sets');
			}
			debugger;
		}
	}, [profileSuccess]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			email: userLogin.email || '',
			password: userLogin.password || '',
		},
		validationSchema: Yup.object({
			email: Yup.string().required(t("Please Enter Your Email")),
			password: Yup.string().required(t("Please Enter Your Password")),
		}),
		onSubmit: (values) => {
			const login = {
				username: values["email"],
				password: values["password"]
			};
			const formData = new FormData();
			formData.append("username", values["email"])
			formData.append("password", values["password"]);
			auth(formData);
		}
	});

	/*const rutValidation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			rut: '',
		},
		validationSchema: Yup.object({
			rut: Yup.string().required("Please Enter Your RUT"),
		}),
		onSubmit: (values) => {
			let rut = values["rut"];
			if(rut.includes("https://portal.sidiv.registrocivil.cl")){
				const params = rut.split('?')[1];
				const urlParams = new URLSearchParams(params);
				rut = urlParams.get('RUN');
			} else if(rut.includes(".")){
				rut = rut.replace(/\./g, "");
			}
			const formData = new FormData();
			formData.append("driverrut", rut);
			rutSearch(formData);
		}
	});*/

	useEffect(() => {
		if (errorMsg) {
			setTimeout(() => {
				dispatch(resetLoginFlag());
			}, 3000);
		}
	}, [dispatch, errorMsg]);

	document.title = "Login | Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-5 mb-4 text-white">
									<p className="mt-3 fs-36 fw-high">{t("PROGISTICA")}</p>
									<p className="mt-3 fs-24 fw-medium">{t("Orders managment platform")}</p>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="mt-4">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<h5 className="text-primary">{t("Welcome")}</h5>
											<p className="text-muted">{t("Sign in to enter the platform.")}</p>
										</div>
										{errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
										<div className="p-2 mt-4">
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
												action="#">

												<div className="mb-3">
													<Label htmlFor="email" className="form-label">{t("Email")}</Label>
													<Input
														name="email"
														className="form-control"
														placeholder="Enter email"
														type="email"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.email || ""}
														invalid={
															validation.touched.email && validation.errors.email ? true : false
														}
													/>
													{validation.touched.email && validation.errors.email ? (
														<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
													) : null}
												</div>

												<div className="mb-3">
													<Label className="form-label" htmlFor="password-input">{t("Password")}</Label>
													<div className="position-relative auth-pass-inputgroup mb-3">
														<Input
															name="password"
															value={validation.values.password || ""}
															type={passwordShow ? "text" : "password"}
															className="form-control pe-5"
															placeholder="Enter Password"
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															invalid={
																	validation.touched.password && validation.errors.password ? true : false
															}
														/>
														{validation.touched.password && validation.errors.password ? (
															<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
														) : null}
														<button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
													</div>
												</div>

												<div className="mt-4">
													<Button color="success" disabled={errorMsg ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
														{loading ? <Spinner size="sm" className='me-2'> {t("Loading")}... </Spinner> : null}
														{t("Sign In")}
													</Button>
												</div>
											</Form>

											{/*<div className="mt-4 text-center">
												<div className="signin-other-title">
													<h5 className="fs-13 mb-4 title">{t("Search your route")}</h5>
												</div>
											</div>

											<Form
												onSubmit={(e) => {
													e.preventDefault();
													rutValidation.handleSubmit();
													return false;
												}}
												action="#">

												<div className="mb-3">
													<Label htmlFor="rut" className="form-label">{t("RUT")}</Label>
													<Input
														name="rut"
														className="form-control"
														placeholder="Enter driver rut"
														type="rut"
														onChange={rutValidation.handleChange}
														onBlur={rutValidation.handleBlur}
														value={rutValidation.values.rut || ""}
														invalid={
															rutValidation.touched.rut && rutValidation.errors.rut ? true : false
														}
													/>
													{rutValidation.touched.rut && rutValidation.errors.rut ? (
														<FormFeedback type="invalid">{rutValidation.errors.rut}</FormFeedback>
													) : null}
												</div>
												
												<div className="mt-1">
													<Button color="primary" disabled={profileError ? null : profileLoading ? true : false} className="btn btn-primary w-100" type="submit">
														{profileLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
														Search Route
													</Button>
												</div>

											</Form>*/}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withTranslation()(withRouter(Login));